import React, { useEffect, useState } from 'react'
import { AgeWrapper } from '../style'
import { carDataSlice } from '../../../store/slices/carDataSlice'
import { useDispatch } from 'react-redux'
export const AgeInput = () => {
    const { setCarAge } = carDataSlice.actions
    const dispatch = useDispatch()
    const [age, setAge] = useState('')
    const handleSelectAge = (e) => {
        if (e.target.value) {
            setAge(e.target.value)
        } else { setAge(null) }
    }

    useEffect(() => {
        const intAge = parseInt(age)
        dispatch(setCarAge(intAge))
    }, [age, setCarAge, dispatch])

    function getYearsArray(startYear) {
        const currentYear = new Date().getFullYear();
        const years = [];
        for (let year = currentYear; year >= startYear; year--) {
            years.push(year);
        }
        return years;
    }
    const yearsArray = getYearsArray(1930);

    return (
        <AgeWrapper>
            <label htmlFor="age">Տարիք</label>
            <select name="age" id="age" onChange={handleSelectAge} required>
            <option  value='' style={{display:'none'}}></option>
                {
                    yearsArray.map((year) =>
                        <option key={year} value={year}>{year}</option>
                    )
                }
            </select>
        </AgeWrapper>
    )
}
