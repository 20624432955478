import styled from "styled-components";

export const CarFormWrapper = styled.div`
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-items: stretch;
    width: 92%;
    border-radius: 20px;
    justify-content: space-between;
    background-color: #f5f5f5;
    padding: 20px;

    .vehicle_and_tonnage {
        width: 100%;
        flex-direction: row;
        justify-content: space-between;
    }

    .engine_and_cube {
        width: 98%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: flex-end;
    div{
        width: 45%;
    }    
}
`

export const EngineTypeWrapper = styled.div`
    & label {
        height: 55px;
        display: flex;
        align-items: center;
    }
`

export const AgeWrapper = styled.div`

    & label {
        height: 55px;
        display: flex;
        align-items: center;
    }
`

export const EngineWrapper = styled.div`
    & label {
        height: 55px;
        display: flex;
        align-items: center;
    }
`

export const VehicleTypeWrapper = styled.div`
    & label {
        height: 55px;
        display: flex;
        align-items: center;
    }
`
export const HighGroundClearanceWrapper = styled.div`
    display: flex;
    flex-direction: row-reverse !important;
    justify-content: flex-end;
    align-items: center;
    gap: 10px;
    margin-top: 15px;
    & input{
        width: 24px;
        height: 24px;
    }
`
export const TonnageWrapper = styled.div`
    & label {
        height: 55px;
        display: flex;
        align-items: center;
    }
`